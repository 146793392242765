// @mui
import { Box, Fab } from '@mui/material'
// hooks
import useResponsive from '~/hooks/useResponsive'
// components
import NextImage from './NextImage'

// images
import WhatsAppIcon from '~/assets/social-icons/whatsapp.png'

/**
 * @param {Object} props
 * @param {string} props.url
 * @returns {JSX.Element}
 */
export default function WhatsAppFab({ url }) {
  const mdUp = useResponsive('up', 'md')

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: { xs: '5rem', md: '3rem' },
        right: { xs: '0.5em', md: '3rem' },
        zIndex: 100
      }}
    >
      <Fab
        component='a'
        href={url}
        target='_blank'
        rel='nofollow noopener noreferrer'
        //
        variant='extended'
        color='primary'
      >
        <NextImage
          src={WhatsAppIcon}
          alt='WhatsApp'
          nextHeight={24}
          nextWidth={24}
          placeholder='empty'
          sx={{
            width: '1.5rem',
            height: '1.5rem',
            marginRight: { xs: '0 !important', md: '0.5rem !important' }
          }}
        />

        {mdUp && 'WhatsApp'}
      </Fab>
    </Box>
  )
}
