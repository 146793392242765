// react
import { useState, useEffect } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { styled } from '@mui/material/styles'
import {
  Box,
  Link,
  Grid,
  List,
  Stack,
  Popover,
  ListItem,
  ListSubheader
} from '@mui/material'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
// components
import NextLink from '~/components/NextLink'

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}))

const LinkSubStyle = styled((props) => (
  <ListItem sx={{ p: 0 }}>
    <Link
      target='_blank'
      rel='noopener'
      {...props}
    >
      {props.children}
    </Link>
  </ListItem>
))(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary
  }
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.isOffset
 * @param {boolean} props.isHome
 * @param {Object[]} props.navConfig
 * @returns {JSX.Element}
 */
export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useRouter()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Stack direction='row'>
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {boolean} props.isHome
 * @param {boolean} props.isOpen
 * @param {boolean} props.isOffset
 * @param {()=>void} props.onOpen
 * @param {()=>void} props.onClose
 * @returns {JSX.Element}
 */
function MenuDesktopItem({ item, isHome, isOpen, isOffset, onOpen, onClose }) {
  const { pathname } = useRouter()

  const { title, path, isExternal, children } = item

  const isActive = (p) => pathname === p

  if (children) {
    return (
      <>
        <LinkStyle
          onClick={onOpen}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...(isHome && { color: 'common.white' }),
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { opacity: 0.48 })
          }}
        >
          <span>{title}</span>

          {isOpen ? (
            <ExpandLessOutlined sx={{ width: 16, height: 16, ml: 0.5 }} />
          ) : (
            <ExpandMoreOutlined sx={{ width: 16, height: 16, ml: 0.5 }} />
          )}
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorReference='anchorPosition'
          anchorPosition={{ top: 80, left: 0 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              right: 16,
              m: 'auto',
              borderRadius: 2,
              maxWidth: (theme) => theme.breakpoints.values.lg,
              boxShadow: (theme) => theme.customShadows.z24
            }
          }}
        >
          <Grid
            container
            spacing={3}
          >
            {children.map((list) => {
              const { subheader, items } = list

              return (
                <Grid
                  key={subheader}
                  item
                  xs={12}
                  md={subheader === 'Dashboard' ? 6 : 2}
                >
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: 'flex',
                        lineHeight: 'unset',
                        alignItems: 'center',
                        color: 'text.primary',
                        typography: 'overline'
                      }}
                    >
                      <IconBullet type='subheader' /> {subheader}
                    </ListSubheader>

                    {items.map((link) => (
                      <LinkSubStyle
                        key={link.title}
                        href={link.path}
                        sx={{
                          ...(isActive(link.path) && {
                            color: 'text.primary',
                            typography: 'subtitle2'
                          })
                        }}
                      >
                        <IconBullet />
                        {link.title}
                      </LinkSubStyle>
                    ))}
                  </List>
                </Grid>
              )
            })}
          </Grid>
        </Popover>
      </>
    )
  }

  return (
    <LinkStyle
      component={isExternal || isExternal ? 'a' : NextLink}
      target={isExternal || isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer nofollow' : undefined}
      href={path}
      sx={{
        // ...(isHome && { color: 'common.white' }),
        ...(isOffset && { color: 'text.primary' }),
        ...(isActive(path) && {
          color: 'primary.main'
        })
      }}
    >
      {title}
    </LinkStyle>
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} [props.type='item']
 * @returns {JSX.Element}
 */
function IconBullet({ type = 'item' }) {
  return (
    <Box
      sx={{
        width: 24,
        height: 16,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        component='span'
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2
          })
        }}
      />
    </Box>
  )
}
