// @mui
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
  Box
} from '@mui/material'
// components
import Logo from '~/components/Logo'
import SocialsButton from '~/components/SocialsButton'
import NextImage from '~/components/NextImage'
import NextLink from '~/components/NextLink'
import NativeImage from '~/components/NativeImage'
// paths
import { PATH_PAGE } from '~/routes/paths'
// image
import footerPSEBadge from '~/assets/footer/pse-badge.png'

// component specific
import paymentMethodList from './paymentMethodList'

// ----------------------------------------------------------------------

const LINKS = [
  {
    category: 'MySkill.id',
    links: [
      {
        title: 'Tentang',
        href: 'https://about-myskill.my.canva.site/',
        isExternal: true
      },
      {
        title: 'Karir',
        href: 'https://clicky.id/hiringmyskill',
        isExternal: true
      },
      { title: 'Kerjasama', href: PATH_PAGE.corporateService },
      { title: 'Blog', href: PATH_PAGE.blog, isExternal: true }
    ]
  },
  {
    category: 'Produk',
    links: [
      { title: 'E-learning', href: PATH_PAGE.elearningMain },
      { title: 'Bootcamp & Program', href: PATH_PAGE.bootcampMain },
      { title: 'Review CV', href: PATH_PAGE.mentoring.reviewMain },
      { title: 'Corporate Service', href: PATH_PAGE.corporateService }
    ]
  },
  {
    category: 'Lainnya',
    links: [
      {
        title: 'FAQ',
        href: 'https://docs.google.com/document/d/16V5abGIukrIUnODigjTz59eFw4-036Uir22dnhgDWjA/view',
        isExternal: true
      },
      {
        title: 'Syarat dan Ketentuan',
        href: PATH_PAGE.legal.termsAndCondition
      },
      {
        title: 'Ketentuan Privasi',
        href: PATH_PAGE.legal.privacyPolicy
      }
    ]
  }
]

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function MainFooter() {
  return (
    <Box
      position='relative'
      backgroundColor='background.default'
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Stack
          alignItems={{ xs: 'center', md: 'flex-start' }}
          marginBottom={2}
        >
          <Logo height={32} />

          <Typography>Rintis Karir Impian bersama MySkill</Typography>
        </Stack>

        <Grid
          container
          spacing={2}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          marginBottom={4}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Typography variant='overline'>
              PT. Linimuda Inspirasi Negeri
            </Typography>

            <Typography
              component='address'
              fontSize='small'
              fontWeight='normal'
              color='text.secondary'
            >
              Griya Dros, Jl KH Abdullah Syafe&apos;i No 1 (Tebet Utara Dalam
              34)
              <br />
              Kel. Tebet Timur, Kec. Tebet, Kota Adm. Jakarta Selatan
              <br />
              Prov. DKI Jakarta, 12820
            </Typography>

            <Link
              href='https://api.whatsapp.com/send/?phone=6281212891393'
              target='_blank'
              rel='nofollow noopener noreferrer'
              fontSize='small'
              color='text.secondary'
            >
              +6282223136022
            </Link>

            <Stack
              direction='row'
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              marginTop={4}
              marginBottom={{ xs: 5, md: 0 }}
            >
              <SocialsButton />
            </Stack>
          </Grid>

          {LINKS.map((list) => (
            <Grid
              item
              key={list.category}
              xs={12}
              md={2}
            >
              <Stack spacing={0.25}>
                <Typography
                  component='p'
                  variant='overline'
                >
                  {list.category}
                </Typography>

                {list.links.map(({ title, href, isExternal }) => (
                  // eslint noopener bugging out because of isExternal conditional
                  // so, just disable error for this one
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <Link
                    key={title}
                    component={isExternal ? 'a' : NextLink}
                    href={href}
                    target={isExternal && '_blank'}
                    rel={isExternal && 'noreferrer noopener nofollow'}
                    //
                    fontSize='small'
                    color='inherit'
                  >
                    {title}
                  </Link>
                ))}
              </Stack>
            </Grid>
          ))}

          <Grid
            item
            xs='auto'
          >
            <a
              href='https://pse.kominfo.go.id/tdpse-detail/3180'
              target='_blank'
              rel='noreferrer noopener nofollow'
            >
              <NextImage
                src={footerPSEBadge}
                alt='PSE terdaftar'
                nextWidth={48}
                nextHeight={48}
                sx={{ width: '3rem', height: '3rem' }}
              />
            </a>
          </Grid>
        </Grid>

        <div>
          <Typography
            marginBottom={0.25}
            textAlign={{ xs: 'center', md: 'left' }}
            fontSize='small'
            color='text.secondary'
          >
            Metode Pembayaran
          </Typography>

          <Grid
            container
            spacing={0.5}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
          >
            {paymentMethodList.map(({ name, logo }) => (
              <Grid
                key={`payment-method-${name}`}
                item
              >
                <Box
                  padding={0.3}
                  paddingY={0.15}
                  borderRadius={1}
                  border='1px solid'
                  sx={{ borderColor: 'divider' }}
                >
                  <NativeImage
                    src={logo}
                    alt={name}
                    sx={{
                      objectFit: 'contain',
                      backgroundImage: 'none',
                      width: '2rem',
                      height: '2rem'
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>

        <Typography
          component='p'
          marginTop={4}
          paddingBottom={4}
          fontSize='small'
          textAlign={{ xs: 'center', md: 'left' }}
        >
          &copy; 2022 - 2023. All rights reserved
        </Typography>
      </Container>
    </Box>
  )
}
