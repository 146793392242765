// react
import { useMemo } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { Box, Stack } from '@mui/material'
// components
import WhatsAppFab from '~/components/WhatsAppFab'

// layout specific
import MainFooter from './MainFooter'
import MainHeader from './MainHeader'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.isClearNavbar=true]
 * @param {boolean} [props.isClearFooter=true]
 * @returns {JSX.Element}
 */
export default function MainLayout({
  children,
  isClearNavbar = true,
  isClearFooter = true
}) {
  const { pathname } = useRouter()

  const whatsAppURL = useMemo(() => {
    if (
      pathname === '/mentoring/review' ||
      pathname === '/mentoring/review/my-review'
    )
      return 'http://wa.me/+6283899294145'

    if (pathname === '/experience')
      // [TODO]: change to different admin number for MySkill Experience
      return 'https://api.whatsapp.com/send/?phone=6281212891393&text=Halo+MySkill%2C+saya+mau+tanya+'

    return 'https://api.whatsapp.com/send/?phone=6281212891393&text=Halo+MySkill%2C+saya+mau+tanya+'
  }, [pathname])

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Box
        sx={{
          paddingTop: isClearNavbar ? { xs: 8, md: 11 } : 0,
          paddingBottom: isClearFooter ? 8 : 0
        }}
      >
        {children}
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <MainFooter />

      <WhatsAppFab url={whatsAppURL} />
    </Stack>
  )
}
