// react
import { useState, useEffect } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { alpha, styled } from '@mui/material/styles'
import {
  Box,
  List,
  Drawer,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@mui/material'
import {
  ExpandLessOutlined,
  ExpandMoreOutlined,
  MenuOutlined
} from '@mui/icons-material'
// config
import { LAYOUT_NAVBAR } from '~/config'
// components
import NextLink from '~/components/NextLink'
import Logo from '~/components/Logo'
import { IconButtonAnimate } from '~/components/animate'
import { NavSectionVertical } from '~/components/nav-section'

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  textTransform: 'capitalize',
  height: LAYOUT_NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  color: theme.palette.text.secondary
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.navConfig
 * @returns {JSX.Element}
 */
export default function MenuMobile({ navConfig }) {
  const { pathname } = useRouter()

  const [open, setOpen] = useState(false)

  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleDrawerOpen}
        sx={{
          mr: 1,
          color: 'text.primary'
        }}
      >
        <MenuOutlined />
      </IconButtonAnimate>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List disablePadding>
            {navConfig.map((link) => (
              <MenuMobileItem
                key={link.title}
                item={link}
                isOpen={open}
                onOpen={handleOpen}
              />
            ))}
          </List>
        </>
      </Drawer>
    </>
  )
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {boolean} props.isOpen
 * @param {()=>void} props.onOpen
 * @returns {JSX.Element}
 */
function MenuMobileItem({ item, isOpen, onOpen }) {
  const { pathname } = useRouter()
  const { title, path, icon, isExternal, children } = item

  const isActive = pathname === path

  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            disableTypography
            primary={title}
          />
          {isOpen ? (
            <ExpandLessOutlined sx={{ width: 16, height: 16, ml: 1 }} />
          ) : (
            <ExpandMoreOutlined sx={{ width: 16, height: 16, ml: 1 }} />
          )}
        </ListItemStyle>

        <Collapse
          in={isOpen}
          unmountOnExit
        >
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <NavSectionVertical navConfig={children} />
          </Box>
        </Collapse>
      </>
    )
  }

  return (
    <ListItemStyle
      component={isExternal || isExternal ? 'a' : NextLink}
      target={isExternal || isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer nofollow' : undefined}
      href={path}
      sx={{
        ...(isActive && {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            )
        })
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        disableTypography
        primary={title}
      />
    </ListItemStyle>
  )
}
