// @mui
import {
  AssignmentOutlined,
  BookOutlined,
  CorporateFareOutlined,
  HomeOutlined,
  NewspaperOutlined,
  RateReviewOutlined
} from '@mui/icons-material'
// routes
import { PATH_PAGE } from '~/routes/paths'

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
}

const menuConfig = [
  {
    title: 'Home',
    icon: <HomeOutlined {...ICON_SIZE} />,
    path: PATH_PAGE.landing
  },
  {
    title: 'E-learning',
    icon: <BookOutlined {...ICON_SIZE} />,
    path: PATH_PAGE.elearningMain
  },
  {
    title: 'Bootcamp & Program',
    icon: <AssignmentOutlined {...ICON_SIZE} />,
    path: PATH_PAGE.bootcampMain
  },
  {
    title: 'Review CV',
    icon: <RateReviewOutlined {...ICON_SIZE} />,
    path: PATH_PAGE.mentoring.reviewMain
  },
  {
    title: 'Corporate Service',
    icon: <CorporateFareOutlined {...ICON_SIZE} />,
    path: PATH_PAGE.corporateService
  },
  {
    title: 'Blog',
    icon: <NewspaperOutlined {...ICON_SIZE} />,
    isExternal: true,
    path: PATH_PAGE.blog
  }
]

export default menuConfig
