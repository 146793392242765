// next
import { useRouter } from 'next/router'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material'
// other libs
import { m } from 'framer-motion'
// hooks
import useOffSetTop from '~/hooks/useOffSetTop'
import useResponsive from '~/hooks/useResponsive'
// utils
import cssStyles from '~/libs/cssStyles'
// config
import { LAYOUT_HEADER } from '~/config'
import { PATH_AUTH } from '~/routes/paths'
// components
import { MotionContainer, varFade } from '~/components/animate'
import NextLink from '~/components/NextLink'
import Logo from '~/components/Logo'
import AccountPopover from '~/components/AccountPopover'
// hooks
import useAuth from '~/hooks/useAuth'
//
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import navConfig from './MenuConfig'

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: LAYOUT_HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: LAYOUT_HEADER.MAIN_DESKTOP_HEIGHT
  }
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
  const { user } = useAuth()
  const isOffset = useOffSetTop(1)

  const theme = useTheme()

  const { pathname, asPath } = useRouter()

  const isDesktop = useResponsive('up', 'md')

  const isHome = pathname === '/'

  return (
    <Box sx={{ zIndex: 'appBar' }}>
      <MotionContainer>
        <m.div variants={varFade().inDown}>
          <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
            <ToolbarStyle
              disableGutters
              sx={{
                ...(isOffset && {
                  ...cssStyles(theme).bgBlur(),
                  height: { md: LAYOUT_HEADER.MAIN_DESKTOP_HEIGHT - 16 }
                })
              }}
            >
              <Container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {!isDesktop && (
                  <MenuMobile
                    isOffset={isOffset}
                    isHome={isHome}
                    navConfig={navConfig}
                  />
                )}
                <Logo />

                <Box sx={{ flexGrow: 1 }} />

                {isDesktop && (
                  <MenuDesktop
                    isOffset={isOffset}
                    isHome={isHome}
                    navConfig={navConfig}
                  />
                )}

                {user?.id ? (
                  <AccountPopover />
                ) : (
                  <>
                    <Button
                      component={NextLink}
                      href={PATH_AUTH.login(asPath)}
                      variant='outlined'
                      sx={{ mr: 1 }}
                    >
                      Masuk
                    </Button>

                    <Button
                      component={NextLink}
                      href={PATH_AUTH.register()}
                      variant='contained'
                      color='primary'
                    >
                      Daftar
                    </Button>
                  </>
                )}
              </Container>
            </ToolbarStyle>
          </AppBar>
        </m.div>
      </MotionContainer>
    </Box>
  )
}
