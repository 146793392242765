const paymentMethodList = [
  {
    name: 'BCA',
    logo: 'https://image.myskill.id/payment-logo/64w/bca.png'
  },
  {
    name: 'BJB',
    logo: 'https://image.myskill.id/payment-logo/64w/bjb.png'
  },
  {
    name: 'BNI',
    logo: 'https://image.myskill.id/payment-logo/64w/bni.png'
  },
  {
    name: 'BRI',
    logo: 'https://image.myskill.id/payment-logo/64w/bri.png'
  },
  {
    name: 'BSI',
    logo: 'https://image.myskill.id/payment-logo/64w/bsi.png'
  },
  {
    name: 'Mandiri',
    logo: 'https://image.myskill.id/payment-logo/64w/mandiri.png'
  },
  {
    name: 'Permata',
    logo: 'https://image.myskill.id/payment-logo/64w/permata.png'
  },
  {
    name: 'QRIS',
    logo: 'https://image.myskill.id/payment-logo/64w/qris.png'
  },
  {
    name: 'AstraPay',
    logo: 'https://image.myskill.id/payment-logo/64w/astrapay.png'
  },
  {
    name: 'Dana',
    logo: 'https://image.myskill.id/payment-logo/64w/dana.png'
  },
  {
    name: 'GoPay',
    logo: 'https://image.myskill.id/payment-logo/64w/gopay.png'
  },
  {
    name: 'LinkAja',
    logo: 'https://image.myskill.id/payment-logo/64w/linkaja.png'
  },
  {
    name: 'OVO',
    logo: 'https://image.myskill.id/payment-logo/64w/ovo.png'
  },
  {
    name: 'ShopeePay / SPayLater',
    logo: 'https://image.myskill.id/payment-logo/64w/shopeepay.png'
  },
  // Retail
  {
    name: 'Alfamart',
    logo: 'https://image.myskill.id/payment-logo/64w/alfamart.png'
  },
  {
    name: 'Indomaret',
    logo: 'https://image.myskill.id/payment-logo/64w/indomaret.png'
  },
  // CC Principal
  {
    name: 'Visa',
    logo: 'https://image.myskill.id/payment-logo/64w/visa.png'
  },
  {
    name: 'Mastercard',
    logo: 'https://image.myskill.id/payment-logo/64w/mastercard.png'
  },
  {
    name: 'JCB',
    logo: 'https://image.myskill.id/payment-logo/64w/jcb.png'
  },
  {
    name: 'American Express',
    logo: 'https://image.myskill.id/payment-logo/64w/american-express.png'
  },
  {
    name: 'Cicilan BCA',
    logo: 'https://image.myskill.id/payment-logo/64w/cicilan-bca.png'
  }
]

export default paymentMethodList
