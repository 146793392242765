// @mui
import { Link, Stack, Tooltip, IconButton } from '@mui/material'
// components
import NextImage from './NextImage'

// images
import TiktokIcon from '~/assets/social-icons/tiktok.png'
import InstagramIcon from '~/assets/social-icons/instagram.png'
import LinkedinIcon from '~/assets/social-icons/linkedin.png'
import TwitterIcon from '~/assets/social-icons/twitter.png'
import YouTubeIcon from '~/assets/social-icons/youtube.png'

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'Tiktok',
    icon: TiktokIcon,
    URL: 'https://www.tiktok.com/@myskill.id'
  },
  {
    name: 'Instagram',
    icon: InstagramIcon,
    URL: 'https://www.instagram.com/myskill.id'
  },
  {
    name: 'Linkedin',
    icon: LinkedinIcon,
    URL: 'https://www.linkedin.com/school/myskillid/'
  },
  {
    name: 'Twitter',
    icon: TwitterIcon,
    URL: 'https://twitter.com/myskill_id'
  },
  {
    name: 'YouTube',
    icon: YouTubeIcon,
    URL: 'https://www.youtube.com/c/MySkill01'
  }
]

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} [props.initialColor]
 * @param {Object} [props.sx]
 */
export default function SocialsButton({ sx, ...other }) {
  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      alignItems='center'
    >
      {SOCIALS.map(({ name, icon, URL }) => (
        <Link
          key={name}
          href={URL}
          target='_blank'
          rel='noreferrer noopener nofollow'
        >
          <Tooltip
            title={name}
            placement='top'
          >
            <IconButton
              color='inherit'
              {...other}
            >
              <NextImage
                src={icon}
                alt={name}
                nextWidth={20}
                nextHeight={20}
                placeholder='empty'
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                  objectFit: 'contain'
                }}
              />
            </IconButton>
          </Tooltip>
        </Link>
      ))}
    </Stack>
  )
}
